html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
