/* PORTFOLIO */
/* ======== */
/* SETTINGS */
/* ======== */
/* Palette */
/* Fonts */
/* Transitions & Settings */
/* Breakpoints */
/* Mixins */
.Portfolio,
.Scene,
.scene-wrapper {
  width: 100vw;
  height: 100vh; }

.Scene {
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1; }
  .Scene canvas {
    pointer-events: none; }
  .Scene.Scene-ready canvas {
    pointer-events: all;
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important; }
    .Scene.Scene-ready canvas:active, .Scene.Scene-ready canvas.active-grab {
      cursor: grabbing !important;
      cursor: -moz-grabbing !important;
      cursor: -webkit-grabbing !important; }

.Portfolio {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  z-index: 1; }
  .Portfolio .portfolio-wrapper {
    width: 30%;
    margin-top: 220px;
    padding-bottom: 20px;
    max-width: 450px; }
    @media screen and (min-width: 1000px) {
      .Portfolio .portfolio-wrapper {
        position: absolute;
        margin-top: 0;
        height: 125px;
        width: 30%; } }
    @media screen and (min-width: 1200px) {
      .Portfolio .portfolio-wrapper {
        padding-bottom: 0;
        height: auto; } }
    .Portfolio .portfolio-wrapper > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%; }
      @media screen and (min-width: 768px) {
        .Portfolio .portfolio-wrapper > div {
          flex-direction: row; } }
    .Portfolio .portfolio-wrapper:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #FFF;
      z-index: 1;
      opacity: 0;
      transition: all .5s ease-out; }
      @media screen and (min-width: 1200px) {
        .Portfolio .portfolio-wrapper:before {
          opacity: 1; } }
  .Portfolio .Resume {
    font-family: "Comfortaa", sans-serif;
    margin-top: 60%;
    width: 100%;
    color: #FFF; }
  .Portfolio .projects {
    list-style-type: none; }
    .Portfolio .projects .item-images {
      display: none; }
  .Portfolio .item {
    position: relative;
    -webkit-transition: all 4s ease-out;
    -moz-transition: all 4s ease-out;
    transition: all 4s ease-out;
    margin: 10px 0;
    cursor: pointer;
    z-index: 2; }
    @media screen and (min-width: 1000px) {
      .Portfolio .item {
        margin: 0; } }
  .Portfolio .item-wrap {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(1);
    transition: all .5s ease-in-out;
    z-index: 9; }
    .Portfolio .item-wrap:hover {
      transform: scale(1.3); }
  .Portfolio .item-frame {
    position: relative;
    background-position: center;
    background-size: 100%;
    background-color: #FFF;
    border-radius: 50%;
    overflow: hidden;
    opacity: .9;
    transform: scale(1);
    transition: all .5s ease-in-out;
    z-index: 9; }
    .Portfolio .item-frame .item-text {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(80, 20, 20, 0.7);
      transition: all ease 0.5s;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: .5rem;
      color: #FFF; }
      .Portfolio .item-frame .item-text span {
        max-width: 50%; }
    .Portfolio .item-frame:hover {
      background-size: 150%;
      opacity: 1; }
      .Portfolio .item-frame:hover .item-text {
        opacity: 1; }
  .Portfolio .item-link-wrap {
    overflow: visible; }
  .Portfolio line,
  .Portfolio .item-link {
    stroke: #FFF;
    stroke-width: 1; }

/* SVG Styles */
