/* Connect */
/* ======== */
/* SETTINGS */
/* ======== */
/* Palette */
/* Fonts */
/* Transitions & Settings */
/* Breakpoints */
/* Mixins */
.Connect {
  width: 100%;
  z-index: 999;
  transition: bottom 2s ease; }

.Connect .social {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 15px 0 0;
  margin: 0;
  list-style-type: none;
  text-align: center; }
  .Connect .social li {
    position: relative;
    display: inline-block;
    transition: all .4s ease-in-out;
    transform: translate(0, 0);
    padding: 0 .5rem; }
    @media screen and (min-width: 1000px) {
      .Connect .social li {
        margin: .25rem 0; } }
    .Connect .social li:last-child {
      margin-bottom: 0; }
  .Connect .social a {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    font-size: 1.5rem;
    color: #FFF;
    transition: all ease 0.5s;
    top: 0; }
    .App-home .Connect .social a {
      opacity: 0.8; }
    .Connect .social a:hover {
      opacity: 1; }
    .Connect .social a span {
      display: none; }
    .Connect .social a i {
      pointer-events: none;
      height: 0; }
