/* Header */
/* ======== */
/* SETTINGS */
/* ======== */
/* Palette */
/* Fonts */
/* Transitions & Settings */
/* Breakpoints */
/* Mixins */
.Header {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  transition: all 1.5s ease;
  z-index: 5;
  color: #FFF; }
  .App-explore .Header {
    background-color: rgba(0, 0, 0, 0.35); }
  @media (max-width: 1399px) {
    .App-about .Header,
    .App-connect .Header {
      background-color: rgba(0, 0, 0, 0.6); } }
  @media (min-width: 1400px) {
    .Header {
      width: 100vw; } }
  .App-explore .Header {
    height: 15vh;
    border-bottom: 1px dashed #FFF; }
    @media (min-width: 1400px) {
      .App-explore .Header {
        border-bottom: 0;
        border-right: 1px dashed #FFF;
        height: 100vh;
        width: 30vw; } }
  @media (max-width: 1399px) {
    .App-about .Header,
    .App-connect .Header {
      height: 100vh; } }
  .Header .Header__wrap {
    position: relative;
    max-width: 340px; }
    .touchevents .Header .Header__wrap {
      margin-bottom: 75px; }
    .touchevents .App-explore .Header .Header__wrap {
      margin-bottom: 0; }
    @media (min-width: 1400px) {
      .Header .Header__wrap {
        margin-top: 30px;
        max-width: 370px; }
        .touchevents .Header .Header__wrap {
          margin-bottom: 0; } }

.Title {
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased; }
  .Title h1, .Title h2 {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.25);
    line-height: 1;
    transition: all 0.5s ease-in-out; }
  .Title h1 {
    font-family: "Chango", sans-serif;
    font-size: 1.96rem;
    letter-spacing: 2px;
    font-weight: 400;
    margin: 0; }
    .no-webanimations .Title h1 {
      font-size: 2rem; }
    @media screen and (min-width: 1400px) and (max-width: 1599px) {
      .Title h1 {
        margin: 0;
        font-size: 2.15rem; }
        .no-webanimations .Title h1 {
          font-size: 2.2rem; } }
    @media screen and (min-width: 1600px) {
      .Title h1 {
        font-size: 2.22rem; }
        .no-webanimations .Title h1 {
          font-size: 2.2rem; } }
  .Title h2 {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 8px 0;
    border-bottom: 1px solid #FFF;
    font-size: 0.85rem;
    font-family: "Comfortaa", sans-serif;
    font-weight: 600;
    letter-spacing: 2px; }
    .touchevents .Title h2,
    .no-webanimations .Title h2 {
      letter-spacing: 1.25px; }
    .touchevents .Title h2 {
      font-size: 0.8rem; }
    @media screen and (min-width: 1400px) and (max-width: 1599px) {
      .Title h2 {
        font-size: 0.94rem; }
        .no-webanimations .Title h2 {
          font-size: 0.908rem; } }
    @media screen and (min-width: 1600px) {
      .Title h2 {
        font-size: 0.99rem; }
        .no-webanimations .Title h2 {
          font-size: 0.93rem; } }

.Menu {
  padding: 0 3px; }
  .Menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: stretch; }
  .Menu li {
    background-color: rgba(0, 0, 0, 0.35);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    margin: 0 3px;
    overflow: hidden;
    cursor: pointer;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .App-home .Menu li {
      background-color: rgba(0, 0, 0, 0.55); }
    .Menu li:first-child {
      margin-left: 0; }
    .Menu li:last-child {
      margin-right: 0; }
    .Menu li.home:hover button,
    .App-home .Menu li.home button {
      background-color: rgba(82, 165, 107, 0.5); }
    .Menu li.explore:hover button,
    .App-explore .Menu li.explore button {
      background-color: rgba(68, 151, 203, 0.5); }
    .Menu li.about:hover button,
    .App-about .Menu li.about button {
      background-color: rgba(205, 105, 11, 0.5); }
    .Menu li.connect:hover button,
    .App-connect .Menu li.connect button {
      background-color: rgba(224, 190, 0, 0.5); }
  .Menu button {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    text-decoration: none;
    text-align: center;
    padding: 8px 0 6px;
    color: #FFF;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Comfortaa", sans-serif;
    display: block;
    width: 100%;
    transition: all ease 0.5s;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px dashed #FFF;
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .Menu button:focus {
      outline: 0; }
    .Menu button:hover, .Menu button:active, .Menu button:-webkit-any-link:active {
      color: #FFF; }

.Info {
  width: 100%;
  box-sizing: border-box; }
  .App-home .Info {
    position: absolute; }
  @media (min-width: 1400px) {
    .Info {
      position: absolute; } }
