/* About */
/* ======== */
/* SETTINGS */
/* ======== */
/* Palette */
/* Fonts */
/* Transitions & Settings */
/* Breakpoints */
/* Mixins */
.About {
  overflow: hidden; }
  .About .about-wrapper {
    padding-top: 15px; }
  .About .about-inner {
    background-color: rgba(0, 0, 0, 0.35);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    margin: 0 3px;
    padding: 10px 15px;
    font-family: "Comfortaa", sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: justify;
    border-radius: 10px;
    border: 1px dashed white;
    transition: all .4s ease-in-out; }
    .App-home .About .about-inner {
      background-color: rgba(0, 0, 0, 0.55); }
  .About p {
    margin: 0; }
